require('./bootstrap');
import 'alpinejs';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

tippy('.tooltip-add', {
    content: 'Add',
    placement: 'bottom'
});

tippy('.tooltip-delete', {
    content: 'Delete',
    placement: 'bottom',
});

tippy('.tooltip-edit', {
    content: 'Edit',
    placement: 'bottom',
});

tippy('.tooltip-view', {
    content: 'View',
    placement: 'bottom',
});
